import React, { useState, useEffect, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "@elements/Button"

const Section = styled.section`
  ${tw`px-offset sm:px-12 lg:px-16 xl:px-24`}
`
const IframeWrap = styled.div`
  ${tw` aspect-w-16 aspect-h-9`}
`
const ImageWrap = styled.div`
  ${tw`relative aspect-w-16 aspect-h-9`}
`
const ScrollContainer = styled.div`
  ${tw`absolute top-0 bottom-0 left-0 right-0 overflow-auto pointer-events-none`}
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Wrap = styled.div`
  ${tw`absolute top-0 bottom-0 left-0 right-0 flex justify-center overflow-hidden`}
`
const ScrollDown = styled.span`
  ${tw`absolute mx-auto font-bold text-white cursor-pointer font-firacode bottom-6 text-30 xl:text-40`}

  & span {
    ${tw`block transform rotate-90`}
  }

  &.bounce {
    animation: bounce 1s infinite ease-out;
  }

  @keyframes bounce {
    0% {
      transform: translateY(-20%);
    }
    50% {
      transform: translateY(10%);
    }
    100% {
      transform: translateY(-20%);
    }
  }
`
const ScrollUp = styled.span`
  ${tw`absolute mx-auto font-bold text-white cursor-pointer font-firacode top-6 text-30 xl:text-40`}

  & span {
    ${tw`block transform -rotate-90`}
  }
`

const WpAcfVideoImageRefBlock = ({ moduleData }) => {
  const blockData = moduleData?.acfRefVideoImageBlock
  const sectionAttributes = moduleData?.attributes
  const sectionId = sectionAttributes?.anchor || ""
  const image = getImage(blockData.image?.localFile)
  const imageFull = getImage(blockData.fullImage?.localFile)
  const scrollContainerRef = useRef()
  const scrollDownRef = useRef()

  const [scrollFromTop, setScrollFromTop] = useState(0)

  const [youtubeCookieAccepted, setYoutubeCookieAccepted] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined" && typeof window.klaro !== "undefined") {
      setYoutubeCookieAccepted(window.klaro.getManager().consents.youtube)
    }
  }, [])

  const handleArrowClick = useCallback(
    event => {
      const element = scrollContainerRef.current
      const scrollOffset = element.getBoundingClientRect().height
      if (event.target.parentElement.classList.contains("arrow-down")) {
        element.scrollTo({
          left: 0,
          top: scrollFromTop + scrollOffset,
          behavior: "smooth",
        })
        setScrollFromTop(
          currentScrollFromTop => currentScrollFromTop + scrollOffset
        )
        scrollDownRef.current.classList.remove("bounce")
      }
      if (event.target.parentElement.classList.contains("arrow-up")) {
        element.scrollTo({
          left: 0,
          top: scrollFromTop - scrollOffset,
          behavior: "smooth",
        })
        setScrollFromTop(
          currentScrollFromTop => currentScrollFromTop - scrollOffset
        )
        if (scrollFromTop < 800) {
          scrollDownRef.current.classList.add("bounce")
        }
      }
    },
    [scrollContainerRef, scrollFromTop]
  )
  useEffect(() => {
    if (scrollDownRef.current !== undefined && scrollDownRef.current !== null) {
      scrollDownRef.current?.classList.add("bounce")
    }
  }, [])

  return (
    <Section id={sectionId}>
      {blockData.type === "video" &&
        blockData.embedId !== null &&
        !blockData.loopedVideo?.publicUrl && (
          <IframeWrap
            className={`yt-iframe ${
              youtubeCookieAccepted ? "block" : "hidden"
            }`}
          >
            <iframe
              title={`Referenz video ${blockData.embedId}`}
              src={`https://www.youtube-nocookie.com/embed/${blockData.embedId}?rel=0`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          </IframeWrap>
        )}
      {blockData.type === "video" &&
        blockData.embedId !== null &&
        !blockData.loopedVideo?.publicUrl && (
          <div
            className={`yt-button flex flex-col items-center justify-center mt-10 ${
              youtubeCookieAccepted ? "hidden" : "flex"
            }`}
          >
            {/* load video button with onclick setting klaro youtube to true, below it small grey legal text */}
            <Button
              onClick={() => {
                window.klaro.getManager().updateConsent("youtube", true)
                window.klaro.getManager().saveAndApplyConsents()
                setYoutubeCookieAccepted(true)
              }}
              buttonText="Video laden"
              buttonHref="/"
              buttonClasses="bg-yellow text-black"
            />
            <p className="mt-6 text-xs text-center text-gray-500 font-firacode">
              Mit dem Laden des Videos akzeptieren Sie die Datenschutzerklärung
              von YouTube.
            </p>
          </div>
        )}
      {blockData.type === "video" && !!blockData.loopedVideo?.publicUrl && (
        <IframeWrap>
          <video title={`Referenz video`} autoPlay loop muted playsInline>
            <source src={blockData.loopedVideo.publicUrl} type="video/mp4" />
          </video>
        </IframeWrap>
      )}
      {blockData.type === "image" && image !== null && image !== undefined && (
        <ImageWrap>
          <GatsbyImage image={image} alt={blockData.image?.altText} />
        </ImageWrap>
      )}
      {blockData.type === "image_slide" &&
        imageFull !== null &&
        imageFull !== undefined && (
          <ImageWrap>
            <Wrap>
              <ScrollContainer ref={scrollContainerRef}>
                <GatsbyImage
                  image={imageFull}
                  alt={blockData.fullImage?.altText}
                />
              </ScrollContainer>
              <ScrollDown
                ref={scrollDownRef}
                onClick={handleArrowClick}
                className="arrow-down"
                style={{ color: blockData.arrowsColor ?? "#fff" }}
              >
                <span>{`->>`}</span>
              </ScrollDown>

              {scrollFromTop > 400 && (
                <ScrollUp
                  onClick={handleArrowClick}
                  className="arrow-up"
                  style={{ color: blockData.arrowsColor ?? "#fff" }}
                >
                  <span>{`->>`}</span>
                </ScrollUp>
              )}
            </Wrap>
          </ImageWrap>
        )}
    </Section>
  )
}

WpAcfVideoImageRefBlock.propTypes = {
  moduleData: PropTypes.object,
}

export default WpAcfVideoImageRefBlock
